// Based off of https://usehooks.com/useAuth/
import React, {useState, useEffect, useContext, createContext} from 'react'

import {useFirebase, firestoreCollection} from './helpers'
import useAmplitude from './useAmplitude'
import useConvertKit from './useConvertKit'

type AuthObject = {
  isLoading: boolean
  user: firebase.User | null
  signIn: (email: string, password: string) => Promise<'success' | 'invalid-credentials' | 'error'>
  signOut: () => Promise<'success' | 'error'>
  signUp: (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => Promise<'success' | 'used-email' | 'invalid-email' | 'weak-password' | 'error'>
  sendPasswordResetEmail: (email: string) => Promise<'success' | 'invalid-email' | 'no-user' | 'error'>
}

const useProvideAuth = (): AuthObject => {
  const {auth, firestore} = useFirebase()
  const {setUserId, logEvent} = useAmplitude()
  const {addTags} = useConvertKit()
  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useState<firebase.User | null>(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user)
        setUserId(user.uid)
      } else {
        setUser(null)
        setUserId(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  const signIn = async (email: string, password: string) => {
    setLoading(true)
    try {
      const response = await auth.signInWithEmailAndPassword(email, password)

      // On success
      setUser(response.user)
      setLoading(false)
      return 'success'
    } catch (error) {
      setLoading(false)
      switch (error.code) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          return 'invalid-credentials'
        default:
          // Log error message
          console.error({
            code: error.code,
            message: error.message,
          })

          // TODO: Formal error logging
          return 'error'
      }
    }
  }

  const signOut = async () => {
    try {
      await auth.signOut()

      setUser(null)
      return 'success'
    } catch (error) {
      // Log error message
      console.error({
        code: error.code,
        message: error.message,
      })

      // TODO: Formal error logging
      return 'error'
    }
  }

  const signUp = async (firstName, lastName, email, password) => {
    setLoading(true)
    try {
      // Create user in Firebase Authentication
      const response = await auth.createUserWithEmailAndPassword(email, password)

      const tempUser = response.user as firebase.User

      // Create user in Firestore
      await firestore.collection(firestoreCollection('users-flat')).doc(tempUser?.uid).set(
        {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim(),
          uid: tempUser.uid,
          proStatus: 'inactive',
          customerId: null,
          events: [],
        },
        {merge: true}
      )

      logEvent('sign-up')

      setUser(tempUser)
    } catch (error) {
      setLoading(false)

      switch (error.code) {
        case 'auth/email-already-in-use':
          return 'used-email'
        case 'auth/invalid-email':
          return 'invalid-email'
        case 'auth/weak-password':
          return 'weak-password'
        default:
          // Log error message
          console.error({
            code: error.code,
            message: error.message,
          })

          // TODO: Formal error logging
          return 'error'
      }
    }

    // Add user's email to ConvertKit
    await addTags(email.trim(), [], firstName.trim())
    setLoading(false)
    return 'success'
  }

  const sendPasswordResetEmail = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email)
      return 'success'
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          return 'invalid-email'
        case 'auth/user-not-found':
          return 'no-user'
        default:
          // TODO: formal error logging
          console.error(error)

          return 'error'
      }
    }
  }

  return {
    isLoading,
    user,
    signIn,
    signOut,
    signUp,
    sendPasswordResetEmail,
  }
}

const AuthContext = createContext<Partial<AuthObject>>({})

export const ProvideAuth = ({children}) => {
  const auth = useProvideAuth() as AuthObject

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext) as AuthObject
}
