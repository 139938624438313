import React from 'react'
import Amplitude from 'amplitude-js'

import {ProvideAuth, ProvideUser} from './src/hooks'

// Log Pageviews
const amplitude = Amplitude.getInstance()
amplitude.init(process.env.AMPLITUDE_API_KEY, undefined, {includeReferrer: true, includeUtm: true})
amplitude.logEvent('pageview', {url: window.location.href})

if (process.env.ENVIRONMENT === 'development') {
  console.log('Event: pageview', {url: window.location.href})
}

export const wrapRootElement = ({element}) => (
  <ProvideAuth>
    <ProvideUser>{element}</ProvideUser>
  </ProvideAuth>
)
