import {convertKitTags, ConvertKitTag} from './constants'

type AddTagsFunction = (email: string, tags: Array<ConvertKitTag>, firstName?: string) => Promise<boolean>
type ChangeFirstNameFunction = (email: string, firstName: string) => Promise<boolean>
interface UseConvertKit {
  addTags: AddTagsFunction
  changeFirstName: ChangeFirstNameFunction
}

const useConvertKit = (): UseConvertKit => {
  const addTags: AddTagsFunction = async (email, tags, firstName) => {
    try {
      const tagIds = tags.map((tag) => convertKitTags[tag])

      // Add a tag to all emails added in development for easy removal
      if (process.env.ENVIRONMENT === 'development') {
        tagIds.push(convertKitTags.Dev)
      }

      const body = {
        api_key: process.env.CONVERT_KIT_API_KEY,
        email,
        tags: tagIds,
        first_name: firstName,
      }

      const result = await fetch(`https://api.convertkit.com/v3/tags/2124780/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      const responseBody = await result.json()

      if (result.status !== 200) {
        throw {status: result.status, responseBody}
      }

      return true
    } catch (error) {
      // TODO: formal error logging
      console.error('Failed to add user to ConvertKit:', error)

      return false
    }
  }

  const changeFirstName: ChangeFirstNameFunction = async (email, firstName) => {
    return false
  }

  return {addTags, changeFirstName}
}

export default useConvertKit
