import {useState} from 'react'
import {Nothing} from 'nothing-mock'
import Amplitude from 'amplitude-js'

type LogEventFunction = (event: string, data?: any, callback?: Amplitude.Callback) => Amplitude.LogReturn
type SetUserIdFunction = (uid: string | null) => void
type IdentifyFunction = (
  properties: Array<[key: string, value: number | string | boolean | Array<number | string | boolean>]>
) => void

interface UseAmplitude {
  logEvent: LogEventFunction
  setUserId: SetUserIdFunction
  identify: IdentifyFunction
}

let useAmplitude: () => UseAmplitude = Nothing

if (typeof window !== `undefined`) {
  const amplitude = Amplitude.getInstance()
  amplitude.init(process.env.AMPLITUDE_API_KEY as string, undefined, {includeReferrer: true, includeUtm: true})

  useAmplitude = (): UseAmplitude => {
    const [identity] = useState<Amplitude.Identify>(new amplitude.Identify())

    const logEvent: LogEventFunction = (event, data, callback) => {
      if (process.env.ENVIRONMENT === 'development') {
        console.log(`Event: ${event}`, data)
      }
      return amplitude.logEvent(event, data, callback)
    }

    const setUserId: SetUserIdFunction = (uid) => amplitude.setUserId(uid)

    const identify: IdentifyFunction = (properties) => {
      properties.reduce((prevIdentity, [key, value]) => prevIdentity.set(key, value), identity)
      amplitude.identify(identity)
    }

    return {
      logEvent,
      setUserId,
      identify,
    }
  }
}

export default useAmplitude
