import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'

firebase.initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
})

export const useFirebase = () => {
  return {
    auth: firebase.auth(),
    firestore: firebase.firestore(),
    functions: firebase.functions(),
  }
}

export const firestoreCollection = (collectionName: string) =>
  process.env.ENVIRONMENT === 'development' ? `${collectionName}-dev` : collectionName
