// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-open-startup-jsx": () => import("./../../../src/pages/open-startup.jsx" /* webpackChunkName: "component---src-pages-open-startup-jsx" */),
  "component---src-pages-pathways-jsx": () => import("./../../../src/pages/pathways.jsx" /* webpackChunkName: "component---src-pages-pathways-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-topics-jsx": () => import("./../../../src/pages/topics.jsx" /* webpackChunkName: "component---src-pages-topics-jsx" */),
  "component---src-templates-pathway-tsx": () => import("./../../../src/templates/pathway.tsx" /* webpackChunkName: "component---src-templates-pathway-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-topic-tsx": () => import("./../../../src/templates/topic.tsx" /* webpackChunkName: "component---src-templates-topic-tsx" */)
}

