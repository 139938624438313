const priceIdMatrix = {
  development: {
    annual: {
      usd: 'price_1I75k4DULhgqhbyBDEe7ZAjT',
      // inr: 'price_1HM3XODULhgqhbyBZWOZXv5b',
    },
    monthly: {
      usd: 'price_1I75jZDULhgqhbyBYylYDydH',
      // inr: 'price_1HM3XCDULhgqhbyB7rqUrhei',
    },
  },
  production: {
    annual: {
      usd: 'price_1I74ljDULhgqhbyBvvaH8fO4',
      // inr: 'price_1HM3W8DULhgqhbyBRxnbjNmv',
    },
    monthly: {
      usd: 'price_1I74lNDULhgqhbyBfD1Qj9CK',
      // inr: 'price_1HM3VZDULhgqhbyBglL8FDax',
    },
  },
}

export const priceIds = priceIdMatrix[process.env.ENVIRONMENT as string]

// To get all the IDs, send a GET request to
//  https://api.convertkit.com/v3/tags?api_key=<our_api_key>
interface ConvertKitTags {
  'Dormant User': number
  'Project Start': number
  'Project Complete': number
  Creator: number
  Professional: number
  Student: number
  Hobbyist: number
  'Python Beginner': number
  'Python Advanced': number
  'Skipped Onboarding': number
  'Python Intermediate': number
  'Transferred User': number
  'Website User': number
  Dev: number
}

export const convertKitTags: ConvertKitTags = {
  'Dormant User': 1777424,
  'Project Start': 2052006,
  'Project Complete': 2052009,
  Creator: 2052044,
  Professional: 2122114,
  Student: 2122118,
  Hobbyist: 2122120,
  'Python Beginner': 2122121,
  'Python Advanced': 2122122,
  'Skipped Onboarding': 2122148,
  'Python Intermediate': 2122198,
  'Transferred User': 2124777,
  'Website User': 2124780,
  Dev: 2124864,
}

export type ConvertKitTag = keyof ConvertKitTags
