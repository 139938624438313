import {useEffect, useState} from 'react'

const useTimer = (interval?: number) => {
  const [seconds, setSeconds] = useState(0)
  const [isActive, setIsActive] = useState(false)

  const start = () => setIsActive(true)
  const restart = () => setSeconds(0)
  const stop = () => {
    setIsActive(false)
    restart()
  }

  useEffect(() => {
    let timer: number | undefined = undefined

    if (isActive) {
      timer = setInterval(() => setSeconds((prevSeconds) => prevSeconds + 1), interval ?? 1000)
    } else if (timer) {
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [isActive])

  return {
    seconds,
    isActive,
    start,
    restart,
    stop,
  }
}

export default useTimer
