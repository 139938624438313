import React, {useState, useEffect, useContext, createContext} from 'react'
import {useFirebase, firestoreCollection} from './helpers'
import {useAuth} from './useAuth'
import useConvertKit from './useConvertKit'
import useAmplitude from './useAmplitude'
import {ConvertKitTag} from './constants'

type Event = 'onboarded' | 'transferred'

interface UserObject {
  firstName: string
  lastName: string
  email: string
  uid: string
  proStatus: 'inactive' | 'active'
  customerId: string | null
  events: Array<Event>
}

interface ProjectObject {
  slug: string
  watchedLectures: Array<string>
}

interface TopicObject {
  slug: string
  watchedLectures: Array<string>
}

type ContentType = 'projects' | 'topics'

type SetProgressFunction = (contentType: ContentType, slug: string, videoSource?: string) => Promise<boolean>
type StoreEventFunction = (event: Event) => Promise<boolean>
interface UseUser {
  isLoading: boolean
  user: UserObject | null
  projects: Array<ProjectObject> | null
  topics: Array<TopicObject> | null
  setProgress: SetProgressFunction
  updateUser: (params: {newFirstName: string; newLastName: string}) => Promise<boolean>
  storeEvent: StoreEventFunction
}

const useProvideUser = (): UseUser => {
  const {firestore} = useFirebase()
  const {user: authUser, isLoading: isAuthLoading} = useAuth()
  const {identify} = useAmplitude()
  const {addTags} = useConvertKit()
  const [isLoading, setLoading] = useState(true)
  const [user, setUser] = useState<UserObject | null>(null)
  const [projects, setProjects] = useState<Array<ProjectObject> | null>(null)
  const [topics, setTopics] = useState<Array<TopicObject> | null>(null)
  const [unsubscribe, setUnsub] = useState<(() => void) | null>(null)

  useEffect(() => {
    if (user && user !== null) {
      identify([['proStatus', user.proStatus]])
    }
  }, [user?.proStatus])

  // Controls the "isLoading" boolean
  useEffect(() => {
    if (!isAuthLoading) {
      if (authUser === null) {
        setLoading(false)
      } else if (user && user !== null && projects !== null && topics !== null) {
        setLoading(false)
      }
    } else setLoading(true)
  }, [isAuthLoading, user, projects, topics])

  useEffect(() => {
    const getProdUserDoc = async () => {
      // If it's the first time logging into the new site, move over their old data
      if (authUser !== null) {
        try {
          const oldDoc = await firestore.collection('users').doc(authUser.uid).get()

          // Check if the user has data on the old site
          if (oldDoc.exists) {
            const newDoc = await firestore.collection(firestoreCollection('users-flat')).doc(authUser.uid).get()

            // Check if this user does not have data on the new site
            if (!newDoc.exists) {
              const oldData = oldDoc.data()
              if (!oldData) throw 'Old user data returned undefined.'

              const {personalInfo, proMembership} = oldData
              firestore
                .collection(firestoreCollection('users-flat'))
                .doc(authUser.uid)
                .set({
                  email: personalInfo.email,
                  firstName: personalInfo.firstName,
                  lastName: personalInfo.lastName,
                  uid: authUser.uid,
                  proStatus: proMembership.status,
                  customerId: proMembership.customerId ?? null,
                  events: ['transferred'],
                })

              console.log('User data copied from old data!')

              const tags = ['Transferred User'] as Array<ConvertKitTag>
              if (proMembership.status === 'active') {
                tags.push('Creator')
              }

              await addTags(personalInfo.email, tags, personalInfo.firstName)
              console.log('User added to ConvertKit!')
            }
          }
        } catch (error) {
          console.error('Failed to copy user data from prod:', error)
        }
      }

      // If it's the first time logging into the dev environment,
      //  copy over the prod user info
      if (authUser !== null && process.env.ENVIRONMENT === 'development') {
        try {
          const devDoc = await firestore.collection('users-flat-dev').doc(authUser.uid).get()

          // Check if there is already user information
          if (!devDoc.exists) {
            const prodDoc = await firestore.collection('users-flat').doc(authUser.uid).get()

            // Check if this user has signed up in prod already
            if (prodDoc.exists) {
              const prodData = prodDoc.data()
              if (!prodData) throw 'User data from prod returned undefined.'

              const {firstName, lastName, uid, email} = prodData
              firestore
                .collection('users-flat-dev')
                .doc(authUser.uid)
                .set({firstName, lastName, uid, proStatus: 'inactive', email})

              console.log('User data copied from prod!')
            }
          }
        } catch (error) {
          console.error('Failed to copy user data from prod:', error)
        }
      }
    }
    getProdUserDoc()
  }, [authUser])

  // Updates state when firestore changes
  useEffect(() => {
    if (authUser === null) {
      if (unsubscribe !== null) {
        unsubscribe()
        setUser(null)
        setProjects(null)
        setTopics(null)
        setUnsub(null)
      }
      return
    }

    const unsubUser = firestore
      .collection(firestoreCollection('users-flat'))
      .doc(authUser.uid)
      .onSnapshot((doc) => {
        const data = doc.data() as UserObject

        setUser({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          uid: data.uid,
          proStatus: data.proStatus,
          customerId: data.customerId ?? null,
          events: data.events ?? [],
        })
      })

    const unsubProjects = firestore
      .collection(firestoreCollection('users-flat'))
      .doc(authUser.uid)
      .collection('projects')
      .onSnapshot((query) => {
        const p: Array<ProjectObject> = []
        query.forEach((doc) => {
          const data = doc.data()
          p.push({
            slug: doc.id,
            watchedLectures: data.watchedLectures,
          })
        })
        setProjects(p)
      })

    const unsubTopics = firestore
      .collection(firestoreCollection('users-flat'))
      .doc(authUser.uid)
      .collection('topics')
      .onSnapshot((query) => {
        const t: Array<TopicObject> = []
        query.forEach((doc) => {
          const data = doc.data()
          t.push({
            slug: doc.id,
            watchedLectures: data.watchedLectures,
          })
        })
        setTopics(t)
      })

    const unsub = () => {
      unsubUser()
      unsubProjects()
      unsubTopics()
    }

    setUnsub(() => () => unsub())

    return () => unsub()
  }, [authUser])

  const setProgress: SetProgressFunction = async (contentType, slug, videoSource) => {
    if (authUser === null || projects === null || topics === null) return false

    const getWatchedAlready = () => {
      switch (contentType) {
        case 'projects':
          return (projects.find((project) => project.slug === slug)?.watchedLectures as string[]) ?? []
        case 'topics':
          return (topics.find((topic) => topic.slug === slug)?.watchedLectures as string[]) ?? []
      }
    }

    if (!videoSource) {
      try {
        await firestore
          .collection(firestoreCollection('users-flat'))
          .doc(authUser.uid)
          .collection(contentType)
          .doc(slug)
          .set({watchedLectures: []})

        return true
      } catch (error) {
        console.error('Error setting project as started: ', error)
        // TODO: formal error logging

        return false
      }
    }

    try {
      if (!getWatchedAlready().includes(videoSource)) {
        await firestore
          .collection(firestoreCollection('users-flat'))
          .doc(authUser.uid)
          .collection(contentType)
          .doc(slug)
          .set({watchedLectures: [...getWatchedAlready(), videoSource]}, {merge: true})
      }
      return true
    } catch (error) {
      console.error('Error setting lecture as watched: ', error)
      // TODO: formal error logging

      return false
    }
  }

  const updateUser = async ({newFirstName, newLastName}) => {
    if (authUser === null) return false

    // TODO: Change first name in convert kit
    // TODO: When implementing change email, don't forget to change email in ConvertKit as well

    const newInfo = {firstName: newFirstName, lastName: newLastName}
    if (newFirstName === '') delete newInfo.firstName
    if (newLastName === '') delete newInfo.lastName

    try {
      await firestore.collection(firestoreCollection('users-flat')).doc(authUser.uid).set(newInfo, {merge: true})
      return true
    } catch (error) {
      // TODO: formal error logging
      console.error('Error updating user info: ', error)

      return false
    }
  }

  const storeEvent: StoreEventFunction = async (event) => {
    if (authUser === null || user === null) return false

    try {
      if (!user.events.includes(event)) {
        await firestore
          .collection(firestoreCollection('users-flat'))
          .doc(authUser.uid)
          .set({events: [...user.events, event]}, {merge: true})
      }
      return true
    } catch (error) {
      // TODO: formal error logging
      console.error('Error storing event on user: ', error)

      return false
    }
  }

  return {
    isLoading,
    user,
    projects,
    topics,
    setProgress,
    updateUser,
    storeEvent,
  }
}

const UserContext = createContext<Partial<UseUser>>({})

export const ProvideUser = ({children}) => {
  const userFunctions = useProvideUser() as UseUser

  return <UserContext.Provider value={userFunctions}>{children}</UserContext.Provider>
}

export const useUser = () => {
  return useContext(UserContext) as UseUser
}
