import {loadStripe, StripeError, RedirectToCheckoutOptions} from '@stripe/stripe-js/pure'
import {useFirebase} from './helpers'
import {priceIds} from './constants'

let stripePromise
const getStripe = () => {
  if (!stripePromise) stripePromise = loadStripe(process.env.STRIPE_API_KEY as string)
  return stripePromise
}

type CreateStripeCheckoutSessionFunction = (
  options: {
    plan: 'annual' | 'monthly'
    currency: 'usd' //| 'inr'
  },
  user: {
    email: string
    uid: string
    customerId: string | null
  }
) => Promise<
  | 'error'
  | 'failure'
  | {sessionId: string; redirect: (options: RedirectToCheckoutOptions) => Promise<{error: StripeError}>}
>

type CreateStripeBillingPortalFunction = (customerId: string) => Promise<'error' | 'failure' | {url: string}>

type Functions = {
  createStripeCheckoutSession: CreateStripeCheckoutSessionFunction
  createStripeBillingPortal: CreateStripeBillingPortalFunction
}

export default (): Functions => {
  const {functions} = useFirebase()

  const fbFunction = (functionName: string) => (data) =>
    functions.httpsCallable(functionName)({
      env: process.env.ENVIRONMENT,
      ...data,
    })

  const createStripeCheckoutSession: CreateStripeCheckoutSessionFunction = async ({plan, currency}, user) => {
    try {
      const {data} = await fbFunction('createStripeCheckoutSession')({
        origin: window.location.origin,
        priceId: priceIds[plan][currency],
        ...user,
      })

      if (data.error) return 'failure'

      const stripe = await getStripe()
      if (stripe === null) throw 'Failed to load Stripe JS.'

      return {
        redirect: stripe.redirectToCheckout,
        sessionId: data.sessionId,
      }
    } catch (error) {
      // TODO: formal error logging
      console.error('Error creating checkout session: ', error)
      return 'error'
    }
  }

  const createStripeBillingPortal: CreateStripeBillingPortalFunction = async (customerId) => {
    try {
      const {data} = await fbFunction('createStripeBillingPortal')({
        origin: window.location.origin,
        customerId,
      })

      if (data.error) return 'failure'

      return {
        url: data.url as string,
      }
    } catch (error) {
      // TODO: formal error logging
      console.error('Error creating billing portal: ', error)
      return 'error'
    }
  }

  return {
    createStripeCheckoutSession,
    createStripeBillingPortal,
  }
}
